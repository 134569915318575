import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main.vue'
import NotFound from '../views/NotFound.vue'
import Home from '../views/Home.vue'
import ProductFamily from '../views/ProductFamily.vue'
import Product from '../views/Product.vue'
import Aftersale from '../views/Aftersale.vue'
import ProductSearch from '../views/ProductSearch.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/home' },
  {
    path: '/main',
    component: Main,
    children: [
      { path: '/home', component: Home },
      { path: '/product-families/:id', component: ProductFamily },
      { path: '/products/:id', component: Product },
      { path: '/after-sales', component: Aftersale },
      { path: '/product-searches', component: ProductSearch }
    ]
  },
  { path: '*', component: NotFound }
]

const router = new VueRouter({
  routes
})

export default router
