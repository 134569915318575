<template>
  <div class="my-content">
    <div class="my-bread-box">
      <el-breadcrumb
        class="my-breadcrumb"
        separator-class="el-icon-arrow-right"
      >
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>热门产品系列</el-breadcrumb-item>
        <el-breadcrumb-item
          :to="{ path: `/product-families/${product.familyId}` }"
          >{{ product.familyName }}</el-breadcrumb-item
        >
        <el-breadcrumb-item>{{ product.subFamilyName }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ product.name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-row :gutter="24">
      <el-col :span="5">
        <el-menu :unique-opened="true">
          <el-submenu
            v-for="item in families"
            :key="item.id"
            :index="String(item.id)"
          >
            <template slot="title">
              <span @click="gotoFamily(item.id)">{{ item.name }}</span>
            </template>
            <el-menu-item
              v-for="sItem in item.subFamilies"
              :key="sItem.id"
              @click="gotoFamily(item.id)"
            >
              <span>{{ sItem.name }}</span>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-col>
      <el-col :span="19">
        <div class="my-product-box">
          <el-row :gutter="24">
            <el-col :span="10">
              <el-image
                class="my-product-image"
                :src="
                  product.images.length > 0
                    ? '/images/' + product.images[0].name
                    : '/allmeek.png'
                "
              ></el-image>
            </el-col>
            <el-col :span="14">
              <div class="my-product-title">{{ product.name }}</div>
              <el-form label-width="70px" label-position="left" size="mini">
                <el-form-item label="价格：">
                  <span class="my-price">￥{{ price }}</span>
                </el-form-item>
                <el-form-item label="货号：">
                  <span class="my-number">{{ number }}</span>
                </el-form-item>
                <el-form-item label="规格：">
                  <el-radio-group v-model="selectedSpecification" size="mini">
                    <el-radio-button
                      v-for="s in product.specifications"
                      :key="s.id"
                      :label="s.id"
                      >{{ s.name }}</el-radio-button
                    >
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="说明书：">
                  <el-link
                    class="my-document"
                    :underline="false"
                    v-if="product.document"
                    :href="'/images/' + product.document.hash"
                    target="_blank"
                  >
                    {{ product.document.name }}
                  </el-link>
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>
          <el-tabs>
            <el-tab-pane label="产品详情">
              <div
                v-if="product.detail"
                v-html="product.detail.html"
                class="my-detail"
              ></div>
            </el-tab-pane>
            <el-tab-pane label="应用实例">
              <div
                v-if="product.practice"
                v-html="product.practice.html"
                class="my-detail"
              ></div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data () {
    return {
      product: {
        images: []
      },
      selectedSpecification: null,
      price: 0,
      number: '',
      families: []
    }
  },
  created () {
    this.getProduct()
    this.getFamilies()
  },
  methods: {
    async getProduct () {
      const id = this.$route.params.id
      const { data: res } = await this.$http.get(`/products/${id}`)
      this.product = res
      this.selectedSpecification = this.product.specifications[0].id
    },
    async getFamilies () {
      const { data: res } = await this.$http.get('/product-families')
      this.families = res
    },
    gotoFamily (id) {
      this.$router.push(`/product-families/${id}`)
    }
  },
  watch: {
    selectedSpecification: function (id) {
      this.product.specifications.forEach((s) => {
        if (s.id === id) {
          this.price = s.price
          this.number = s.number
        }
      })
    },
    product: function (product) {
      document.title = product.name
    }
  }
}
</script>

<style lang="less" scoped>
.my-content {
  margin: 0 auto;
  width: 1200px;
}
.my-bread-box {
  height: 48px;
  margin-top: 24px;
  margin-bottom: 24px;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.my-breadcrumb {
  font-size: 14px;
  margin-left: 12px;
}
.my-product-box {
  width: 900px;
}
.my-product-title {
  font-size: 22px;
  margin-bottom: 20px;
}
.my-detail {
  font-size: 0.9em;
}
.my-price {
  color: #cc0000;
  font-size: 18px;
}
.my-number {
  font-size: 16px;
}
.my-document {
  transform: translateY(-2px);
}
</style>
