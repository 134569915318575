<template>
  <div class="my-family-box">
    <div class="my-bread-box">
      <el-breadcrumb
        class="my-breadcrumb"
        separator-class="el-icon-arrow-right"
      >
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>热门产品系列</el-breadcrumb-item>
        <el-breadcrumb-item>{{ activeFamily.name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-row :gutter="24">
      <el-col :span="5">
        <el-menu :unique-opened="true">
          <el-submenu
            v-for="item in families"
            :key="item.id"
            :index="String(item.id)"
          >
            <template slot="title">
              <span @click="gotoFamily(item.id)">{{ item.name }}</span>
            </template>
            <el-menu-item
              v-for="sItem in item.subFamilies"
              :key="sItem.id"
              @click="gotoFamily(item.id)"
            >
              <span>{{ sItem.name }}</span>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-col>
      <el-col :span="19">
        <div class="my-title-box">
          <div class="my-title">
            {{ activeFamily.name }}
          </div>
          <div class="my-description">
            {{ activeFamily.description }}
          </div>
        </div>
        <div
          class="my-sub-family"
          v-for="item in activeFamily.subFamilies"
          :key="item.id"
        >
          {{ item.name }}
          <div class="my-dash"></div>
          <div>
            <div v-for="p in item.products" :key="p.id">
              <el-link @click="gotoProduct(p.id)">{{ p.name }}</el-link>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data () {
    return {
      families: [],
      activeFamily: {}
    }
  },
  created () {
    this.getFamilies()
  },
  methods: {
    async getFamilies () {
      const { data: res } = await this.$http.get('/product-families')
      this.families = res
      this.setActive()
    },
    setActive () {
      this.families.forEach((f) => {
        if (f.id === Number(this.$route.params.id)) {
          this.activeFamily = f
        }
      })
    },
    gotoProduct (id) {
      this.$router.push(`/products/${id}`)
    },
    gotoFamily (id) {
      if (id === this.activeFamily.id) {
        return
      }
      this.$router.push({
        params: { ...this.$route.params, id: id }
      })
      this.setActive()
    }
  }
}
</script>

<style lang="less" scoped>
.my-family-box {
  margin: 0 auto;
  width: 1200px;
}
.my-bread-box {
  height: 48px;
  margin-top: 24px;
  margin-bottom: 24px;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.my-breadcrumb {
  font-size: 14px;
  margin-left: 12px;
}
.my-title-box {
  margin-bottom: 48px;
}
.my-title {
  font-size: 24px;
  margin-bottom: 24px;
}
.my-description {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}
.my-sub-family {
  margin-bottom: 60px;
}
.my-dash {
  margin-top: 6px;
  margin-bottom: 6px;
  border-top: 2px dashed #004098;
}
</style>
