<template>
  <div class="my-content">
    <div class="my-bread-box">
      <el-breadcrumb
        class="my-breadcrumb"
        separator-class="el-icon-arrow-right"
      >
        <el-breadcrumb-item>搜索结果</el-breadcrumb-item>
        <el-breadcrumb-item>"{{ searchWord }}"</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="my-product-searches-box">
      <div
        class="my-product-box"
        v-for="item in products"
        :key="item.id"
        @click="gotoProduct(item.id)"
      >
        <el-image
          class="my-product-image"
          :src="
            item.images.length > 0
              ? '/images/' + item.images[0].name
              : '/allmeek.png'
          "
        ></el-image>
        <div class="my-product-name">{{ item.name }}</div>
        <div class="my-product-number">
          货号：{{ getNumbers(item.specifications) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      products: [],
      searchWord: ''
    }
  },
  created () {
    this.searchWord = this.$route.query.wd
    this.getProducts()
  },
  methods: {
    async getProducts () {
      const { data: res } = await this.$http.post('/product-searches/', {
        term: this.searchWord
      })
      this.products = res
    },
    gotoProduct (id) {
      this.$router.push(`/products/${id}`)
    },
    getNumbers (specifications) {
      var arr = []
      specifications.forEach((sp) => {
        arr.push(sp.number)
      })
      return arr.join('/')
    }
  },
  watch: {
    '$route.query.wd': function (wd) {
      this.searchWord = wd
    },
    searchWord: function (wd) {
      this.getProducts()
    }
  }
}
</script>

<style lang="less" scoped>
.my-content {
  margin: 0 auto;
  width: 1200px;
}
.my-bread-box {
  height: 48px;
  margin-top: 24px;
  margin-bottom: 24px;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.my-breadcrumb {
  font-size: 14px;
  margin-left: 12px;
}
.my-product-searches-box {
  width: 1200px;
  min-height: 400px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.my-product-box {
  width: 285px;
  margin-bottom: 24px;
  &:hover {
    cursor: pointer;
    .my-product-name {
      color: #0066cc;
    }
  }
}
.my-product-image {
  width: 100%;
  height: 280px;
}
.my-product-name {
  height: 42px;
  font-size: 16px;
  font-weight: 600;
  margin-top: 6px;
  margin-bottom: 6px;
}
.my-product-number {
  font-weight: 400;
  font-size: 14px;
  color: rgba(31, 45, 61, 0.6);
}
</style>
