<template>
  <div class="main">
    <div class="my-header-box">
      <div class="title">
        <div class="logo-box" @click="goHome">
          <img class="logo-img" src="../assets/images/logo.png" />
        </div>
        <div class="search-box">
          <el-autocomplete
            class="search-input"
            placeholder="搜产品"
            v-model="searchWord"
            :fetch-suggestions="getKeywords"
            @select="selectKeyword"
            @keyup.enter.native="enterKeyword"
          ></el-autocomplete>
        </div>
      </div>
    </div>
    <router-view></router-view>
    <div class="my-footer-box">
      <div class="my-info-box">
        <el-row :gutter="24">
          <el-col :span="8">
            <div class="my-info-sub-title">
              <span
                >我们的理念：探索生命奥秘 | 崇尚生态健康 | 信仰和谐共存</span
              >
            </div>
            <div class="my-belief-images-box">
              <img class="my-belief-image" src="../assets/images/info1.png" />
              <img class="my-belief-image" src="../assets/images/info2.png" />
              <img class="my-belief-image" src="../assets/images/info3.png" />
              <img class="my-belief-image" src="../assets/images/info4.png" />
            </div>
          </el-col>
          <el-col :span="8">
            <div class="my-info-sub-title">
              <a class="my-link" href="/#/after-sales">售后服务与技术支持</a
              ><span> | 产品订购与售前咨询</span>
            </div>
            <div class="my-mails-box">
              <div>服务邮箱：</div>
              <div>
                <div class="my-mail">
                  <a class="my-link" href="mailto:service@allmeek.com"
                    >service@allmeek.com (售后服务)</a
                  >
                </div>
                <div class="my-mail">
                  <a class="my-link" href="mailto:support@allmeek.com"
                    >support@allmeek.com (技术支持)</a
                  >
                </div>
                <div class="my-mail">
                  <a class="my-link" href="mailto:sales@allmeek.com"
                    >sales@allmeek.com (销售咨询)</a
                  >
                </div>
                <div class="my-mail">
                  <a class="my-link" href="mailto:marketing@allmeek.com"
                    >marketing@allmeek.com (市场推广)</a
                  >
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="my-info-sub-title">
              <span>ALLMEEK 卓越品质 服务生命科学</span>
            </div>
            <div class="my-qr-code-box">
              <div>
                <img class="my-qr-code" src="../assets/images/wechat.jpg" />
              </div>
              <div class="my-motto-box">
                <div class="my-motto">
                  <span class="my-italic">be</span> SUPERIOR
                </div>
                <div class="my-motto">
                  <span class="my-italic">drive</span> DISCOVERY
                </div>
                <div class="my-motto">
                  <span class="my-italic">keep</span> RELIABLE
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="my-copyright-box">
        <div class="my-copyright-line">
          <el-row :gutter="24">
            <el-col :span="6">
              <div class="my-copyright">
                <span>©{{ year }} 北京傲美生物科技有限公司</span>
              </div>
            </el-col>
            <el-col :span="6">
              <a
                class="my-link"
                :underline="false"
                href="https://beian.miit.gov.cn"
                target="_blank"
                ><span>京ICP备2020042424号-1</span></a
              >
            </el-col>
            <el-col :span="6">
              <div class="my-address">
                <span>地址：北京市昌平区能源东路奇点中心A座</span>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="my-address">
                <a class="my-link" href="tel:+86-400-001-3880"
                  >电话：400-001-3880</a
                >
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      searchWord: '',
      year: new Date().getFullYear()
    }
  },
  methods: {
    goHome () {
      this.$router.push('/home')
    },
    async getKeywords (term, cb) {
      await this.$http
        .post('/product-searches/hot-words', { term: term })
        .then((res) => {
          var keywords = res.data
          keywords.forEach((kw) => {
            kw.value = kw.term
          })
          cb(keywords)
        })
        .catch(() => {})
    },
    selectKeyword (kw) {
      this.gotoSearch(kw.term)
    },
    enterKeyword () {
      this.gotoSearch(this.searchWord)
    },
    gotoSearch (term) {
      term = encodeURIComponent(term)
      this.$router.push(`/product-searches?wd=${term}`)
    }
  },
  watch: {}
}
</script>

<style lang="less" scoped>
.main {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}
.my-header-box {
  width: 100%;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}
.title {
  width: 1200px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logo-box {
  width: 210px;
  transform: translateY(2px);
}
.logo-img {
  width: 100%;
}
.search-box {
  width: 300px;
  .search-input {
    width: 100%;
  }
}
.my-footer-box {
  width: 100%;
  background-color: #303030;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}
.my-info-box {
  width: 1200px;
  margin-top: 24px;
  margin-bottom: 24px;
}
.my-copyright {
  font-size: 14px;
  display: inline-flex;
}
.my-address {
  font-size: 14px;
  display: inline-flex;
}
.my-copyright-box {
  width: 100%;
  height: 60px;
  border-top-style: solid;
  border-top-width: 1px;
  color: #a0a0a0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
.my-link:link {
  color: #a0a0a0;
  font-size: 14px;
  text-decoration: none;
}
.my-link:visited {
  color: #a0a0a0;
  font-size: 14px;
  text-decoration: none;
}
.my-link:hover {
  color: #d0d0d0;
  font-size: 14px;
  text-decoration: none;
}
.my-copyright-line {
  width: 1200px;
}
.my-info-box {
  color: #a0a0a0;
  font-size: 14px;
}
.my-info-sub-title {
  margin-bottom: 18px;
}
.my-belief-image {
  width: 86px;
  height: 111px;
  object-fit: fill;
  margin-right: 4px;
}
.my-qr-code {
  width: 111px;
}
.my-mails-box {
  display: flex;
  flex-direction: row;
}
.my-mail {
  margin-bottom: 8px;
}
.my-motto {
  margin-bottom: 8px;
}
.my-qr-code-box {
  display: flex;
  flex-direction: row;
}
.my-italic {
  font-style: italic;
}
.my-motto-box {
  margin-left: 12px;
}
</style>

<style lang="less">
.search-box {
  .el-input__inner {
    border-radius: 0;
  }
}
</style>
