<template>
  <div class="home">
    <el-carousel
      class="my-carousel"
      trigger="click"
      height="444px"
      :interval="5000"
    >
      <el-carousel-item
        v-for="item in carouselImages"
        :key="item.orderInCarousel"
      >
        <img class="carousel-img" :src="'/images/' + item.displayImage" />
      </el-carousel-item>
    </el-carousel>
    <div class="my-product-familes-title-box">
      <div class="my-product-familes-title">热门产品系列</div>
      <div class="my-under-line"></div>
    </div>
    <div class="my-product-familes-box">
      <div
        class="my-family-box"
        v-for="item in productFamilies"
        :key="item.orderInFamily"
        @click="gotoFamily(item.id)"
      >
        <img class="my-family-image" :src="'/images/' + item.displayImage" />
        <div class="my-family-name">{{ item.name }}</div>
        <div class="my-family-description">{{ item.description }}</div>
      </div>
    </div>
    <div class="my-product-familes-title-box">
      <div class="my-product-familes-title">新闻资讯</div>
      <div class="my-under-line"></div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      searchWord: '',
      carouselImages: [],
      productFamilies: []
    }
  },
  created () {
    this.getHomeInfo()
  },
  methods: {
    async getHomeInfo () {
      const { data: res } = await this.$http.get('/home-info')
      this.carouselImages = res.carouselImages
      this.productFamilies = res.productFamilies
    },
    gotoFamily (id) {
      this.$router.push(`/product-families/${id}`)
    }
  }
}
</script>

<style lang="less" scoped>
.el-carousel {
  width: 1200px;
  margin: 24px 0 0 0;
}
.carousel-img {
  width: 100%;
  height: 100%;
}
.my-product-familes-title-box {
  width: 1200px;
  margin-top: 36px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}
.my-product-familes-title {
  font-size: 30px;
  color: #5870b3;
}
.my-under-line {
  width: 300px;
  margin-top: 6px;
  height: 4px;
  background-image: linear-gradient(
    to right;rgba(32, 55, 117, 0),
    rgba(88, 112, 179, 0.9),
    rgba(88, 112, 179, 0)
  );
}
.my-product-familes-box {
  width: 1200px;
  min-height: 300px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.my-family-box {
  width: 285px;
  margin-bottom: 24px;
  &:hover {
    cursor: pointer;
    .my-family-name {
      color: #0066cc;
    }
  }
}
.my-family-image {
  width: 100%;
  height: 200px;
}
.my-family-name {
  font-size: 16px;
  font-weight: 600;
  margin-top: 6px;
  margin-bottom: 6px;
}
.my-family-description {
  font-weight: 400;
  font-size: 14px;
  color: rgba(31, 45, 61, 0.6);
}
</style>

<style lang="less">
.search-box {
  .el-input__inner {
    border-radius: 0;
  }
}
.my-carousel {
  .el-carousel__button {
    width: 48px;
    height: 6px;
  }
  .el-carousel__arrow {
    width: 48px;
    height: 48px;
    font-size: 30px;
    background-color: rgba(31, 45, 61, 0.22);
    &:hover {
      background-color: rgba(31, 45, 61, 0.66);
    }
  }
}
</style>
