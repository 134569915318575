import Vue from 'vue'
import {
  Button, Input, Link,
  Carousel, CarouselItem,
  Row, Col,
  Menu, MenuItem, Submenu,
  Tabs, TabPane,
  Form, FormItem,
  RadioGroup, RadioButton,
  Image,
  Breadcrumb, BreadcrumbItem,
  Icon, Autocomplete
} from 'element-ui'

Vue.use(Button)
Vue.use(Input)
Vue.use(Link)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Row)
Vue.use(Col)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Submenu)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(RadioGroup)
Vue.use(RadioButton)
Vue.use(Image)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Icon)
Vue.use(Autocomplete)
